import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PageTitle from '../components/Blocks/PageTitle'
import Faq from '../components/Faq'
import { Helmet } from 'react-helmet'
import ReivewsSlider from '../components/ReviewsSlider'
import { CaretRight } from 'phosphor-react'

const BuyingTemplate = ({ title, content, page: { aboutFields }, faq }) => (
  <div className="w-full h-full">
    <PageTitle title={title} />
    {aboutFields && (
      <div className="flex flex-wrap-reverse h-full items-stretch">
        <div
          className="bg-brand-green md:w-1/2 w-full relative px-8 py-10 md:px-20 md:py-20 text-white flex flex-col justify-center"
          style={{ minHeight: '300px' }}
        >
          <h2 className="text-black">{aboutFields.headerTitle}</h2>
          <div
            className="transition-all text-lg my-8 text-black"
            dangerouslySetInnerHTML={{ __html: aboutFields.headerContent }}
          />

          <div className="absolute -top-6 right-0 md:top-0 w-full h-6 md:w-6 md:h-full md:-right-6 bg-brand-green opacity-70" />
          <div className="w-full h-6 md:w-6 md:h-full absolute right-0 -top-12 md:-right-12 md:top-0 bg-brand-green opacity-30" />
        </div>
        <img
          className="object-cover md:w-1/2 w-full"
          src={aboutFields?.headerImage?.mediaItemUrl}
          style={{
            minHeight: '300px',
          }}
        />
      </div>
    )}

    <div className="max-w-screen-xl mx-auto">
      <div
        className="transition-all"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
    <ReivewsSlider />
    <div className="bg-gray-500 py-10 text-center px-8 transition-all hover:bg-brand-blue duration-500 flex justify-center">
      <a
        href="#quoteForm"
        className="text-lg text-white uppercase cursor-pointer flex gap-x-2 items-center font-light"
      >
        Interested in leasing? Learn more <CaretRight />
      </a>
    </div>
  </div>
)

const Page = ({ data, pageContext }) => {
  const { wpPage: page } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} title={page.title} />
      <Helmet>
        <script type="application/ld+json">
          {`[{"@context":"https://schema.org","@type":"AboutPage","mainEntityOfPage":{"@type":"WebPage","@id":"https://www.dmautoleasing.com/about/"},"url":"https://www.dmautoleasing.com/about/","headline":"About D&M | See What Our Customers Say | D&M Auto Leasing","description":"D&M Auto Leasing has several thousands of satisfied customers. With three locations in the DFW area we make auto leasing easy.","publisher":{"@type":"Organization","name":"D&amp;M Auto Leasing","url":"https://www.dmautoleasing.com"}},{"@context":"https://schema.org","@graph":[{"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"https://www.dmautoleasing.com/#Main Menu","name":"Home","url":"https://www.dmautoleasing.com/"}]},{"@context":"https://schema.org","@type":"Review","@id":"https://www.dmautoleasing.com/about/#Review","itemReviewed":{"@type":"LocalBusiness","image":[]},"name":"D&amp;M Auto Leasing","author":{"@type":"Person","name":"Darren Hasley"}}]`}
        </script>
      </Helmet>
      <BuyingTemplate
        page={page}
        title={page.title}
        content={page.content}
        faq={page.faq}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query AboutPageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
      aboutFields {
        headerTitle
        headerContent
        headerImage {
          id
          mediaItemUrl
        }
      }
    }
  }
`
