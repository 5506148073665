import React, { useState } from 'react'
import FaqItem from './FaqItem'

const Faq = ({ items, title, color = 'brand-blue' }) => {
  const [activeIndex, setActiveIndex] = useState()
  const handleClick = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  // No items?
  if (!items || !items.length) {
    return null;
  }
  
  const renderedQuestionsAnswers = items.map((item, index) => {
    const showDescription = index === activeIndex ? 'show' : 'hidden'
    const fontWeightBold = index === activeIndex ? 'font-weight-bold' : ''
    const ariaExpanded = index === activeIndex ? 'true' : 'false'
    
    return (
      <FaqItem
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        onClick={() => handleClick(index)}
        // eslint-disable-next-line react/no-array-index-key
        key={`faq-item-${index}`}
        color={color}
        textColor={color === 'brand-blue' ? 'black' : color}
      />
    )
  })

  return (
    <div className={`mx-auto px-8 md:px-20 max-w-screen-xl mb-10`}>
      <h3 className={`text-center text-${color}`}>{title}</h3>
      <dl className="faq__list">{renderedQuestionsAnswers}</dl>
    </div>
  )
}

export default Faq
